/**
* CSS name convention: BEM
* CSS linting: .stylelintrc
* Global indentation: 4 spaces
* Global units: px
* Browser support: last 3 versions of modern browsers
* Additional notes:
*/

/**
* Import: vars
* Description: variables
*/

/*------------------------------------*\
    # vars.globals
\*------------------------------------*/

/**
 * The $unit variable is a global variable to be used
 * in paddings, margins, for sizing and positioning
 */

/**
 * The $spacer variable is a global variable to be used
 * in to create a unified spacer for all kinds of elements
 */

/**
 * Global class prefix - usage: .$(global-prefix)classname
 */

/**
 * Global radius and rounded
 */

/**
 * Grid
 */

/*------------------------------------*\
    # vars.typography
\*------------------------------------*/

/**
 * Project base line height (unitless in order to work with typomatic)
 */

/**
 * Type scale sizes must be entered in their pixel size
 * (unitless in order to work with typomatic)
 */

/** 
 * Type weights
 */

/**
 * Font stacks
 */

/*------------------------------------*\
    # vars.responsive
\*------------------------------------*/

/* 544px */

/* 543px */

/* 768px */

/* 767px */

/* 992px */

/* 991px */

/* 1200px */

/* 1199px */

/* 1440px */

/* 1439px */

/*------------------------------------*\
    # vars.colors
\*------------------------------------*/

/**
 * Brand colors
 */

/**
 * Global text color
 */

/**
 * Theme colors
 */

/**
 * Utility colors
 */

/**
* Import: tools
* Description: tools like typomatic and other mixins / libraries
*/

/*------------------------------------*\
    # tools.typomatic
\*------------------------------------*/

/**
 * Mixin name: typomatic-init
 * Description: creates a vertical rhythm on a page using font-size
 * and line-height on the html element
 * Parameters: 
 * does not take parameters
 */

/**
 * Mixin name: type-scale
 * Description: type-scale sets the type to baseline to achieve
 * vertical rhythm.
 * Parameters: 
 * $scale ($base-font-size is default) - font size (unitless) variable
 * $baselines (1 is default) - number of baselines
 */

/**
* Import: generic
* Description: normalize or reset CSS, box sizing
*/

/* @import '../../../node_modules/normalize.css'; */

/*------------------------------------*\
    # generic.boxsizing
\*------------------------------------*/

*,
*:before,
*:after {
    -webkit-box-sizing: inherit;
            box-sizing: inherit;
}

html {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

/**
* Import: base
* Description: base structural stylings for setting the body, typography
* and other base styles (no classes should be added here)
*/

/*------------------------------------*\
    # base.globals
\*------------------------------------*/

/**
 * Initialize typomatic in project (sets font-size and line-height
 * on html selector.
 */

html {
    font-size: 100%;
    line-height: 1.5;
}

body {
    position: relative;

    background: #f5f5f5;

    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 400;
    color: #111;

    /* Enable font smoothing for WebKit */
    -webkit-font-smoothing: antialiased;

    /* Global minimum width */
    min-width: 1024px;
}

/* Lock scrolling on mobile nav open */

@media (max-width: 61.9375em) {

body.is-locked {
            overflow: hidden
    }
        }

img {
    max-width: 100%;
    vertical-align: middle;
}

svg {
    max-width: 100%;
}

/*------------------------------------*\
    # base.typography
\*------------------------------------*/

/**
 * Headings
 */

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-weight: 400;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    text-rendering: optimizeLegibility;
}

h1 {
    font-size: 39px;
    line-height: 48px;
}

h2 {
    font-size: 31px;
    line-height: 40px;
}

h3 {
    font-size: 25px;
    line-height: 36px;
}

h4,
h5,
h6 {
    font-size: 18px;
    line-height: 28px;
}

/**
 * Paragraphs
 */

p {
    margin: 0;
}

/**
 * Lists (ol, ul, dd)
 */

ol,
ul,
dl {
    margin: 0;
    padding: 0;
    list-style: none;
}

/**
 * Anchors
 */

a {
    color: #3890EE;
    text-decoration: none;
}

a:hover {
        color: rgb(56, 113, 173);
    }

a:focus {
        color: rgb(56, 113, 173);
    }

/**
 * Typographic details
 */

hr {
    padding: 8px 0;
    border: 0;
    border-bottom: 1px solid rgb(222, 222, 222);
}

em {
}

b,
strong {
    font-weight: 700;
}

/**
 * Tables
 */

table th {
        text-align: left;
    }

/**
* Import: component
* Description: cross project reusable components
*/

/*------------------------------------*\
    # component.container
\*------------------------------------*/

.container {
    position: relative;
}

.container--empty {
    height: 100vh;
}

/*------------------------------------*\
    # component.wrapper
\*------------------------------------*/

.wrapper {
    max-width: 1596px;
    margin: 0 auto;
}

.wrapper--narrow {
    max-width: 480px;
}

/*------------------------------------*\
    # component.logo
\*------------------------------------*/

.logo {
}

.logo__background-gradient {
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, .5)), to(rgba(255, 255, 255, .5)));
    background: linear-gradient(rgba(0, 0, 0, .5), rgba(255, 255, 255, .5));
}

/*------------------------------------*\
    # component.btn
\*------------------------------------*/

.btn {
    /**
     * Button base
     */
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;

    -ms-touch-action: manipulation;

        touch-action: manipulation;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    /**
     * Remove border radius on iOS buttons
     */

    /* stylelint-disable property-no-vendor-prefix */
    -webkit-border-radius: 0;
    /* stylelint-enable */

    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
    text-align: center;
    border: 0;
    border-radius: 4px;
    background: transparent;

    -webkit-transition: all 0.15s ease-out;

    transition: all 0.15s ease-out;
}

.btn:focus {
        outline: 0;
    }

/**
     * Disabled
     */

.btn.is-disabled,
    .btn:disabled {
        cursor: not-allowed;
        opacity: 0.6;
        background-color: transparent;
    }

.btn.is-disabled {
        pointer-events: none;
    }

/**
 * Button styles
 */

.btn--primary {
    background: #3890EE;
    color: white;
    -webkit-box-shadow: 0 2px 5px rgba(34, 34, 34, 0.2);
            box-shadow: 0 2px 5px rgba(34, 34, 34, 0.2);
}

.btn--primary:hover,
    .btn--primary:focus {
        color: white;
        background: rgba(56, 144, 238, 0.7);
    }

.btn--primary:disabled,
    .btn--primary.is-disabled {
        background: #3890EE;
    }

.btn--secondary {
    background: #666666;
    color: white;
}

.btn--secondary:hover,
    .btn--secondary:focus {
        color: white;
        background: rgba(102, 102, 102, 0.7);
    }

.btn--secondary:disabled,
    .btn--secondary.is-disabled {
        background: #666666;
    }

.btn--danger {
    background: #ee386f;
    color: white;
    -webkit-box-shadow: 0 2px 5px rgba(34, 34, 34, 0.2);
            box-shadow: 0 2px 5px rgba(34, 34, 34, 0.2);
}

.btn--danger:hover,
    .btn--danger:focus {
        color: white;
        background: rgba(238, 56, 111, 0.7);
    }

.btn--danger:disabled,
    .btn--danger.is-disabled {
        background: #ee386f;
    }

.btn--dark {
    background: #222;
    color: white;
    -webkit-box-shadow: 0 2px 5px rgba(34, 34, 34, 0.2);
            box-shadow: 0 2px 5px rgba(34, 34, 34, 0.2);
}

.btn--dark:hover,
    .btn--dark:focus {
        color: white;
        background: rgba(34, 34, 34, 0.7);
    }

.btn--dark:disabled,
    .btn--dark.is-disabled {
        background: #222;
    }

.btn--outline {
    background: transparent;
    color: #111;
    -webkit-box-shadow: 0 0 0 1px rgba(34, 34, 34, 0.2) inset;
            box-shadow: 0 0 0 1px rgba(34, 34, 34, 0.2) inset;
}

.btn--outline:hover,
    .btn--outline:focus {
        color: #111;
        -webkit-box-shadow: 0 0 0 1px rgba(34, 34, 34, 0.8) inset;
                box-shadow: 0 0 0 1px rgba(34, 34, 34, 0.8) inset;
    }

.btn--outline:disabled,
    .btn--outline.is-disabled {
        background: transparent;
    }

.btn--ghost {
    background: transparent;
    color: rgba(34, 34, 34, 0.6);
    -webkit-box-shadow: 0 0 0 1px rgba(34, 34, 34, 0) inset;
            box-shadow: 0 0 0 1px rgba(34, 34, 34, 0) inset;
}

.btn--ghost:hover,
    .btn--ghost:focus {
        color: rgba(34, 34, 34, 0.8);
        -webkit-box-shadow: 0 0 0 1px rgba(34, 34, 34, 0.3) inset;
                box-shadow: 0 0 0 1px rgba(34, 34, 34, 0.3) inset;
    }

.btn--ghost:disabled,
    .btn--ghost.is-disabled {
        background: transparent;
    }

/**
 * Spacer - a placeholder for text between
 * two or more buttons
 */

.btn-spacer {
    padding: 0 4px;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    color: rgb(170, 170, 170);
}

/**
 * Button sizes
 */

.btn--sml {
    font-size: 12px;
    line-height: 16px;
    height: 28px;
    padding: 0 12px;
}

.btn--med {
    font-size: 14px;
    line-height: 16px;
    height: 36px;
    padding: 0 24px;
}

.btn--fixed-width {
    width: 145px;
    text-align: center;
}

/**
 * Buttons with icons
 */

.btn--hasIcon span + svg {
    margin-left: 8px;
}

.btn--hasIcon svg + span {
    margin-left: 8px;
}

/**
 * Special buttons
 */

.btn--close {
    width: 28px;
    height: 28px;
    border-radius: 1000px;
}

/*------------------------------------*\
    # component.btn-group
\*------------------------------------*/

.btn-group {
    /**
     * Btngroup base
     */
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.btn-group .btn {
    margin-right: 12px;
}

.btn-group .btn:last-child {
        margin-right: 0;
    }

/*------------------------------------*\
    # component.input
\*------------------------------------*/

.input {
    /**
     * Input base
     */
    padding: 0;
    margin: 0;
    border-radius: 4px;

    display: -webkit-inline-box;

    display: -ms-inline-flexbox;

    display: inline-flex;
    width: 100%;

    cursor: pointer;
    white-space: nowrap;

    text-decoration: none;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif;

    background: none;
    background: white;
    border: 1px solid rgba(34, 34, 34, 0.15);
    color: #222;

    -webkit-transition: all 0.2s ease-out;

    transition: all 0.2s ease-out;
}

.input::-webkit-input-placeholder {
        color: rgba(17, 17, 17, 0.3);
    }

.input::-moz-placeholder {
        color: rgba(17, 17, 17, 0.3);
    }

.input::-ms-input-placeholder {
        color: rgba(17, 17, 17, 0.3);
    }

.input::placeholder {
        color: rgba(17, 17, 17, 0.3);
    }

.input:hover {
        background: rgba(56, 144, 238, 0.05);
        border-color: #3890EE;
    }

.input:focus {
        border-color: #3890EE;
        outline: 0;
    }

.input:focus::-webkit-input-placeholder {
            color: #3890EE;
        }

.input:focus::-moz-placeholder {
            color: #3890EE;
        }

.input:focus::-ms-input-placeholder {
            color: #3890EE;
        }

.input:focus::placeholder {
            color: #3890EE;
        }

.input[disabled] {
        background: rgba(34, 34, 34, 0.06);
        cursor: not-allowed;
    }

/* Input sizes */

.input--sml {
    font-size: 12px;
    line-height: 16px;
    height: 28px;
    line-height: 24px;
    padding: 0 12px;
}

.input--med {
    font-size: 14px;
    line-height: 16px;
    height: 40px;
    line-height: 40px;
    padding: 0 16px;
}

/* Input types */

.input--text {
}

.input--number {
    padding-right: 0;
}

.input--select {
    font-size: 14px;
    line-height: 16px;
    height: 28px;
    line-height: 24px;
}

.input--select--multiple {
    min-height: 160px;
}

.input--textarea {
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    resize: vertical;
    padding: 16px;
    white-space: pre-wrap;
    word-wrap: normal;
    outline: none;

    border: 1px solid rgba(34, 34, 34, 0.08);
    background: white;
    border-radius: 4px;

    -webkit-transition: all 0.2s ease-out;

    transition: all 0.2s ease-out;
}

.input--textarea:hover,
    .input--textarea:focus {
        background: rgba(56, 144, 238, 0.05);
        border-color: #3890EE;
    }

.input--check,
.input--radio {
    position: absolute;
    left: -99999px;
    opacity: 0;
}

.input--check + label, .input--radio + label {
        padding-left: 8px;
        font-size: 14px;
        line-height: 16px;
        color: rgba(17, 17, 17, 0.7);
    }

.input--check + label:before, .input--radio + label:before {
            content: '';
            display: block;
            float: left;
            width: 14px;
            height: 14px;
            margin-top: 8px;

            -webkit-transition: all 0.2s ease-in-out;

            transition: all 0.2s ease-in-out;

            border: 1px solid rgba(34, 34, 34, 0.3);
            border-radius: 4px;
        }

.input--check:hover + label:before, .input--check:focus + label:before, .input--radio:hover + label:before, .input--radio:focus + label:before {
                border-color: #3890EE;
                background: rgba(56, 144, 238, 0.1);
            }

.input--check:checked + label:before, .input--radio:checked + label:before {
                background: #3890EE;
                border-color: transparent;
            }

.input--radio + label:before {
            border-radius: 50%;
        }

.input--check--disabled:hover + label:before, .input--check--disabled:focus + label:before {
                border: 1px solid rgba(34, 34, 34, 0.3);
                background: transparent;
            }

.input--check--disabled:checked + label:before {
                background: #3890EE;
                border-color: transparent;
            }

.input--language {
    padding: 0;
    margin: 0;
    border-radius: 4px;

    display: -webkit-inline-box;

    display: -ms-inline-flexbox;

    display: inline-flex;

    cursor: pointer;
    white-space: nowrap;

    text-decoration: none;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif;

    background: none;
    background: white;
    border: 1px solid rgba(34, 34, 34, 0.15);
    color: #222;

    -webkit-transition: all 0.2s ease-out;

    transition: all 0.2s ease-out;
}

.input--language:hover {
        background: rgba(56, 144, 238, 0.05);
        border-color: #3890EE;
    }

.input--language:focus {
        border-color: #3890EE;
        outline: 0;
    }

/*------------------------------------*\
    # component.field
\*------------------------------------*/

.field {
    /**
     * Field base
     */
    margin-bottom: 24px;
}

/* Field label */

.field__lbl {
    display: block;
    margin-bottom: 4px;
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    color: rgba(17, 17, 17, 0.4);
}

/* Icon on the left or right of the input */

.field__icon {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    pointer-events: none;
}

.field__icon--left {
    left: 0;
}

.field__icon--right {
    right: 0;
}

.empty-field {
    background-color: #666666;
    border-radius: 4px;
    padding: 8px 16px;
}

/*------------------------------------*\
    # component.fieldset
\*------------------------------------*/

.fieldset {
    /**
     * Fieldset base
     */
    padding: 0;
    margin: 0;
    border: 0;
}

.fieldset__legend {
    padding: 32px 0;

    font-size: 12px;

    line-height: 12px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    font-weight: 700;
}

/*------------------------------------*\
    # component.alert
\*------------------------------------*/

.alert {
    position: relative;
    display: block;
    font-size: 12px;
    line-height: 16px;
    padding: 12px;
    border-radius: 4px;
}

.alert:empty {
        display: none;
    }

.alert + .alert {
        margin-top: 4px;
    }

.alert--success {
    background: rgba(0, 128, 0, 0.1);
}

.alert--warning {
    background: rgba(238, 56, 111, 0.1);
}

.alert--note {
    background: rgba(255, 165, 0, 0.1);
}

/*------------------------------------*\
    # component.social
\*------------------------------------*/

.social {
}

/*------------------------------------*\
    # component.hamburger
\*------------------------------------*/

.hamburger {
    display: inline-block;
    cursor: pointer;

    -webkit-transition-property: opacity, -webkit-filter;

    transition-property: opacity, -webkit-filter;

    transition-property: opacity, filter;

    transition-property: opacity, filter, -webkit-filter;
    -webkit-transition-duration: 0.15s;
            transition-duration: 0.15s;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;

    background-color: transparent;
    border: 0;
    margin: 0;
    padding: 10px 12px 4px 12px;
    overflow: visible;
}

.hamburger-box {
    width: 32px;
    height: 30px;
    display: inline-block;
    position: relative;
}

.hamburger-inner {
    display: block;
    top: 50%;
}

.hamburger-inner,
    .hamburger-inner:before,
    .hamburger-inner:after {
        position: absolute;
        width: 30px;
        height: 2px;

        background-color: black;
        -webkit-transition-property: -webkit-transform;
        transition-property: -webkit-transform;
        transition-property: transform;
        transition-property: transform, -webkit-transform;
        -webkit-transition-duration: 0.15s;
                transition-duration: 0.15s;
        -webkit-transition-timing-function: ease;
                transition-timing-function: ease;
    }

.hamburger-inner:before,
    .hamburger-inner:after {
        content: '';
        display: block;
    }

.hamburger-inner:before {
        top: -10px;
    }

.hamburger-inner:after {
        bottom: -10px;
    }

/* Elastic */

.hamburger--elastic .hamburger-inner {
    top: 4px;
    -webkit-transition-duration: 0.275s;
            transition-duration: 0.275s;
    -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
            transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic .hamburger-inner:before {
    top: 8px;
    -webkit-transition: opacity 0.125s 0.275s ease;
    transition: opacity 0.125s 0.275s ease;
}

.hamburger--elastic .hamburger-inner:after {
    top: 16px;
    -webkit-transition: -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic.is-active .hamburger-inner {
    -webkit-transform: translate3d(0, 8px, 0) rotate(135deg);
            transform: translate3d(0, 8px, 0) rotate(135deg);
    -webkit-transition-delay: 0.075s;
            transition-delay: 0.075s;
}

.hamburger--elastic.is-active .hamburger-inner:before {
    -webkit-transition-delay: 0s;
            transition-delay: 0s;
    opacity: 0;
}

.hamburger--elastic.is-active .hamburger-inner:after {
    -webkit-transform: translate3d(0, -16px, 0) rotate(-270deg);
            transform: translate3d(0, -16px, 0) rotate(-270deg);
    -webkit-transition-delay: 0.075s;
            transition-delay: 0.075s;
}

/*------------------------------------*\
    # component.table
\*------------------------------------*/

.table {
    overflow-x: auto;
    overflow-y: hidden;
}

/**
     * Table base
     */

.table thead {
        border-bottom: 1px solid black;
    }

.table th,
    .table td {
        vertical-align: top;
        padding: 8px;
        font-size: 14px;
        line-height: 20px;
    }

.table tr {
        border-bottom: 1px solid black;
    }

.table tr:last-child {
            border-bottom: 1px solid black;
        }

/*------------------------------------*\
    # component.formatted
\*------------------------------------*/

.formatted p,
    .formatted ul,
    .formatted ol,
    .formatted dl,
    .formatted table,
    .formatted object,
    .formatted img {
        margin-bottom: 16px;
    }

.formatted h1,
    .formatted h2,
    .formatted h3,
    .formatted h4,
    .formatted h5,
    .formatted h6 {
        margin: 32px 0;
    }

.formatted ul,
    .formatted ol,
    .formatted dl {
        list-style: inherit;
        list-style-position: inside;
    }

.formatted p:last-child {
            margin-bottom: 0 !important;
        }

/*------------------------------------*\
    # component.list
\*------------------------------------*/

.list {
}

.list--inline:before {
    content: '';
    display: table;
}

.list--inline:after {
    content: '';
    display: table;
    clear: both;
}

.list--inline > * {
        float: left;
        margin-right: 8px;
    }

.list--bordered > * {
        padding: 8px 0;
        border-bottom: 1px solid rgb(212, 213, 213);
    }

.list--commaseparated > * {
        display: inline;
    }

.list--commaseparated > *:after {
            content: ',';
        }

.list--commaseparated > *:last-child:after {
                content: '';
            }

/*------------------------------------*\
    # component.loading
\*------------------------------------*/

@-webkit-keyframes spinnerAnimation {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes spinnerAnimation {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.loading,
.loading:after {
    border-radius: 50%;
    width: 24px;
    height: 24px;
}

.loading {
    position: relative;
    margin: 60px auto;
    font-size: 10px;
    text-indent: -9999em;
    border-top: 2px solid #3890EE;
    border-right: 2px solid #3890EE;
    border-bottom: 2px solid transparent;
    border-left: 2px solid transparent;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-animation: spinnerAnimation 0.5s infinite linear;
            animation: spinnerAnimation 0.5s infinite linear;
}

/*------------------------------------*\
    # component.card
\*------------------------------------*/

.card {
    padding: 40px;
    background: white;
    border-radius: 4px;
}

.card__header {
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(34, 34, 34, 0.1);
}

.card__content {
    padding: 40px 0;
}

.card__footer {
    padding-top: 40px;
    border-top: 1px solid rgba(34, 34, 34, 0.1);
}

/*------------------------------------*\
    # component.label
\*------------------------------------*/

.label {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    padding: 0 12px;

    font-size: 12px;

    line-height: 16px;
    height: 28px;

    border-radius: 1000px;
    color: rgba(17, 17, 17, 0.8);
}

.label--primary {
    background: rgba(56, 144, 238, 0.1);
    border: 1px solid rgba(56, 144, 238, 0.2);
}

.label--success {
    background: rgba(0, 128, 0, 0.1);
    border: 1px solid rgba(0, 128, 0, 0.2);
}

.label--note {
    background: rgba(255, 165, 0, 0.1);
    border: 1px solid rgba(255, 165, 0, 0.2);
}

.label--warning {
    background: rgba(238, 56, 111, 0.1);
    border: 1px solid rgba(238, 56, 111, 0.2);
}

/**
 * Labels with icons
 */

.label--hasIcon span + svg {
    margin-left: 4px;
}

.label--hasIcon svg + span {
    margin-left: 4px;
}

/*------------------------------------*\
    # component.pagination
\*------------------------------------*/

.pagination {
    padding: 40px 0;
}

.pagination__wrapper {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.pagination__arrow {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 52px;
    height: 52px;
    opacity: 0.5;
}

.pagination__numbers {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.pagination__number {
    position: relative;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 52px;
    height: 52px;

    opacity: 0.7;
}

.pagination__number.is-active {
        opacity: 1;
        color: #3890EE;
    }

.pagination__number.is-active:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 50%;

            width: 30%;
            height: 2px;
            -webkit-transform: translateX(-50%);
                    transform: translateX(-50%);

            background: #3890EE;
        }

.pagination__dots {
    opacity: 0.6;
}

/*------------------------------------*\
    # component.masterlayout
\*------------------------------------*/

.masterlayout {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 100vh;
}

.masterlayout__aside {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    background: #3890EE;
}

.masterlayout__main {
    -webkit-box-flex: 4;
        -ms-flex: 4;
            flex: 4;
    padding: 0 48px;
    width: 80vw;
}

/*------------------------------------*\
    # component.contentarea
\*------------------------------------*/

.contentarea {
    padding: 40px 0;
}

.contentarea__heading {
    padding-bottom: 32px;
    margin-bottom: 40px;
    border-bottom: 1px solid rgba(34, 34, 34, 0.1);
}

.contentarea__heading__main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.contentarea__heading__search {
    margin-top: 32px;
}

.contentarea__heading__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.contentarea__heading__action {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}

.contentarea__actionbar {
    padding-top: 32px;
    margin-top: 40px;
    border-top: 1px solid rgba(34, 34, 34, 0.1);
}

/*------------------------------------*\
    # component.itemlist
\*------------------------------------*/

.itemlist {
}

/* Simple item list with title and action buttons */

.itemlist--simple {
}

.itemlist--simple__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    padding: 16px 0;
    border-bottom: 1px solid rgba(34, 34, 34, 0.1);
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    padding-left: 16px;
}

.itemlist--simple__item:hover {
        padding-left: 24px;
        /* margin-left: calc($unit * -4); */
        background: rgba(255, 255, 255, 0.7);
    }

.itemlist--simple__item__content {
    padding-right: 24px;
}

.itemlist--simple__item__action {
}

/*------------------------------------*\
    # component.imageuploader
\*------------------------------------*/

.imageuploader {
    position: relative;
    padding: 20px;
    width: 100%;
    min-height: 360px;
    background: white;
    border-radius: 4px;
    border: 1px solid rgba(34, 34, 34, 0.1);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.imageuploader iframe {
        max-width: 420px;
        width: 100%;
        height: 240px;
        margin-top: 24px;
    }

.imageuploader img {
        margin-top: 24px;
        -o-object-fit: contain;
           object-fit: contain;
    }

.imageuploader__image {
    height: 80%;
}

.imageuploader__remove {
    position: absolute;
    top: 16px;
    right: 16px;
}

.imageuploader__wrapper {
    width: 320px;
}

.imageuploader-gallery {
    padding: 20px;
    width: 100%;
    background: white;
    border-radius: 4px;
    border: 1px solid rgba(34, 34, 34, 0.1);
}

.imageuploader-gallery__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}

.imageuploader-gallery__image {
    position: relative;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    min-height: 100px;
    min-width: 200px;
}

.imageuploader-gallery__image--empty {
        width: 100%;
    }

.imageuploader-gallery__image img {
        max-height: 325px;
        padding: 8px;
    }

/*------------------------------------*\
    # component.summarycard
\*------------------------------------*/

.summarycard {
    padding: 20px;
    background: white;
    border-radius: 4px;
}

.summarycard__header {
    padding-bottom: 20px;
}

.summarycard__footer {
    padding-top: 20px;
}

.badge-levels__title {
    margin: 16px 0;
}

.badge-levels__wrapper {
    width: 100%;
    min-height: 200px;
    padding: 16px 16px;
    margin: 24px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-shadow: rgba(118, 143, 255, .1) 0 6px 36px 0;
            box-shadow: rgba(118, 143, 255, .1) 0 6px 36px 0;
}

.badge-levels__card {
    height: 200px;
    width: 180px;
    border: 2px solid rgba(0, 0, 0, .5);
    border-radius: 4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 20px;
    margin: 20px 16px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.badge-levels__card span {
        font-size: 22px;
        margin-bottom: 40px;
    }

.badge-levels__card input {
        text-align: center;
        width: 60px;
        padding: 8px 0;
    }

.badge-levels__card input::-webkit-inner-spin-button,
        .badge-levels__card input::-webkit-outer-spin-button {
            display: none;
        }

.badge-levels__buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 200px;
    width: 180px;
    padding: 20px;
    margin: 20px 16px;
}

.badge-levels__buttons button {
        margin: 16px 0;
        width: 100%;
        text-align: right;
    }

.badge-levels__save {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.badge-levels__save button {
        border-radius: 4px;
    }

/*------------------------------------*\
    # component.onoffswitch
\*------------------------------------*/

.onoffswitch {
    position: relative;
    width: 72px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.onoffswitch__checkbox {
    display: none;
}

.onoffswitch__label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border-radius: 20px;
}

.onoffswitch__inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    -webkit-transition: margin 0.3s;
    transition: margin 0.3s;
}

.onoffswitch__inner:before,
.onoffswitch__inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 32px;
    padding: 0;
    font-size: 12px;
    line-height: 32px;
    color: white;
}

.onoffswitch__inner:before {
    content: 'ON';
    padding-left: 12px;
    background-color: #3890EE;
    color: #FFFFFF;
}

.onoffswitch__inner:after {
    content: 'OFF';
    padding-right: 12px;
    background-color: rgba(34, 34, 34, 0.1);
    color: #222;
    text-align: right;
}

.onoffswitch__switch {
    display: block;
    width: 16px;
    height: 16px;
    background: #FFFFFF;
    position: absolute;
    top: 8px;
    bottom: 0;
    right: 48px;
    border-radius: 50%;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}

.onoffswitch__checkbox:checked + .onoffswitch__label .onoffswitch__inner {
    margin-left: 0;
}

.onoffswitch__checkbox:checked + .onoffswitch__label .onoffswitch__switch {
    right: 10px;
}

.point__filters {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}

.point__filters > div {
        width: 20%;
        margin-right: 16px;
    }

.redeemed-rewards__filters {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}

.redeemed-rewards__filters > div {
        width: 20%;
        margin-right: 16px;
    }

/**
* Import: block
* Description: specific website/app blocks
*/

/*------------------------------------*\
    # block.header
\*------------------------------------*/

.header {
}

/*------------------------------------*\
    # block.nav
\*------------------------------------*/

.nav {
    position: relative;
}

.nav__list {
}

.nav__list__item {
    margin-bottom: 8px;
    outline: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.nav__link {
    position: relative;
    display: block;
    padding: 12px 0 12px 16px;

    font-size: 14px;

    line-height: 16px;
    color: rgba(255, 255, 255, 0.7);

    -webkit-transition: all 0.2s ease-out;

    transition: all 0.2s ease-out;
    border-radius: 4px;
}

.nav__link:hover,
    .nav__link:focus {
        background: #f5f5f5;
        color: #3890EE;
    }

.nav__link[aria-current] {
        color: #3890EE;
        background: #f5f5f5;
        -webkit-box-shadow: 0 0 10px rgba(34, 34, 34, 0.1);
                box-shadow: 0 0 10px rgba(34, 34, 34, 0.1);
    }

.nav__link--dropdown {
    cursor: pointer;
    outline: none;
}

.nav__link--dropdown:after {
        content: '';
        display: block;
        position: absolute;
        top: 16px;
        right: 16px;
        width: 6px;
        height: 6px;
        border-top: 1px solid rgba(255, 255, 255, 0.5);
        border-right: 1px solid rgba(255, 255, 255, 0.5);

        -webkit-transform: rotate(135deg);

                transform: rotate(135deg);
    }

.nav__link--dropdown:hover:after, .nav__link--dropdown:focus:after, .nav__link--dropdown.nav__button--active:after {
            border-top-color: #3890EE;
            border-right-color: #3890EE;
        }

.nav__link__child {
    margin-left: 16px;
}

.nav__link__child.active {
        background: rgba(255, 255, 255, 0.1);
        color: white;
    }

.nav__button--active {
    color: #3890EE;
    background: #f5f5f5;
    -webkit-box-shadow: 0 0 10px rgba(34, 34, 34, 0.1);
            box-shadow: 0 0 10px rgba(34, 34, 34, 0.1);
}

/*------------------------------------*\
    # block.sidebar
\*------------------------------------*/

.sidebar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 100vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.sidebar__branding {
    padding: 32px;
}

.sidebar__branding img {
    max-width: 200px;
}

.sidebar__nav {
    padding: 0 24px 0 24px;
}

.sidebar__logout {
    width: 100%;
    padding: 20px 40px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);

    font-size: 12px;

    line-height: 12px;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    color: rgba(255, 255, 255, 0.6);
}

.sidebar__logout:hover,
    .sidebar__logout:focus {
        color: rgba(255, 255, 255, 0.8);
    }

.sidebar__version {
    font-size: 12px;
    line-height: 12px;
    background: rgba(255, 255, 255, 0.1);
    padding: 12px;
    margin: 24px;
    border-radius: 4px;
    color: rgba(255, 255, 255, 0.5);
}

/*------------------------------------*\
    # block.branding
\*------------------------------------*/

.branding {
    margin-bottom: 60px;
}

/*------------------------------------*\
    # block.footer
\*------------------------------------*/

.footer {
}

/*------------------------------------*\
    # block.modal
\*------------------------------------*/

.modal {
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    overflow-y: scroll;
    overflow-x: hidden;

    width: 100%;

    opacity: 1;
    pointer-events: all;
    -webkit-transform: scale(1);
            transform: scale(1);

    background-color: rgba(102, 102, 102, 0.4);
}

@media (min-width: 34em) {

.modal {
        padding: 10% 0
}
    }

.modal__wrapper {
    position: relative;
    z-index: 2;

    margin: 0 auto;

    background-color: white;
    -webkit-box-shadow: 1px 1px -2px 10px rgba(0, 0, 0, .4);
            box-shadow: 1px 1px -2px 10px rgba(0, 0, 0, .4);

    -webkit-transition: all 0.2s;

    transition: all 0.2s;
}

@media (min-width: 34em) {

.modal__wrapper {
        width: 53%;
        min-width: 280px;
        border-radius: 4px
}
    }

.modal__content {
    padding: 32px;
    padding-top: 0;
}

.modal__header {
    padding: 32px;
}

.modal__footer {
    padding: 32px;
    padding-top: 0;
}

.modal__close {
    position: absolute;
    z-index: 2;
    top: 12px;
    right: 12px;
}

/*------------------------------------*\
    # block.auth
\*------------------------------------*/

.auth {
    margin-top: 120px;
    background: white;
    border-radius: 4px;
}

.auth__header {
    padding: 40px 40px 20px 40px;
}

.auth__content {
    padding: 0 40px;
}

.auth__action {
    padding: 40px;
}

/*------------------------------------*\
    # block.page
\*------------------------------------*/

.page {
    max-width: 900px;
    padding: 0 60px;
    margin: 0 auto;
}

.page__header {
    padding-top: 40px;
    margin-bottom: 60px;
}

.page__content {
    padding-bottom: 40px;
}

/**
* Import: views
* Description: specific website/app views (example: 404 view, login view)
*/

/* @import 'views.404.css'; */

/*------------------------------------*\
    # views.home
\*------------------------------------*/

.home {
}

/*------------------------------------*\
    # views.localisation
\*------------------------------------*/

/*------------------------------------*\
    # views.localisation
\*------------------------------------*/

.v-localisation {
}

.v-localisation__heading {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-bottom: 32px;
    margin-bottom: 24px;
    border-bottom: 1px solid rgba(34, 34, 34, 0.1);
}

.v-localisation__heading__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}

.v-localisation__heading__action {
}

.v-localisation__admin__actions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.v-localisation__admin__actions > div,
    .v-localisation__admin__actions button {
        margin-right: 16px;
    }

.v-localisation__grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.v-localisation__grid__item {
    -webkit-box-flex: 6;
        -ms-flex: 6;
            flex: 6;
    margin-right: 16px;
}

.v-localisation__grid__item:last-child {
        -webkit-box-flex: 1;
            -ms-flex: 1;
                flex: 1;
    }

/*------------------------------------*\
    # views.feedback
\*------------------------------------*/

.v-feedback {
}

.v-feedback__fromto {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.v-feedback__fromto__left {
    -webkit-box-flex: 5;
        -ms-flex: 5;
            flex: 5;
    padding: 20px;
    background: rgba(56, 144, 238, 0.1);
    border: 1px solid rgba(56, 144, 238, 0.2);
    border-radius: 4px;
}

.v-feedback__fromto__dir {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    margin: 0 32px;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
}

.v-feedback__actiontitle {
}

.v-feedback__actiontitle__title {
}

.v-feedback__actiontitle__arrow {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;

    width: 60px;
    height: 60px;
    background: rgba(56, 144, 238, 0.1);
    border: 1px solid rgba(56, 144, 238, 0.2);
    border-radius: 1000px;
}

.v-feedback__fromto__right {
    -webkit-box-flex: 5;
        -ms-flex: 5;
            flex: 5;
    padding: 20px;
    background: rgba(56, 144, 238, 0.1);
    border: 1px solid rgba(56, 144, 238, 0.2);
    border-radius: 4px;
}

.v-feedback__item {
}

.v-feedback__item__status {
}

/*------------------------------------*\
    # views.badges
\*------------------------------------*/

.v-badges {
}

.v-badges__list {
    display: flex;
    flex-flow: row wrap;
}

.v-badges__list__item {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 3/12 - (30px - 30px * 3/12));
    max-width: calc(99.9% * 3/12 - (30px - 30px * 3/12));
    width: calc(99.9% * 3/12 - (30px - 30px * 3/12));
    position: relative;
    padding: 20px;
    margin-bottom: 28px;

    background: rgba(56, 144, 238, 0.1);
    border: 1px solid rgba(56, 144, 238, 0.2);
    border-radius: 4px;
}

.v-badges__list__item:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
}

.v-badges__list__item:last-child {
    margin-right: 0;
}

.v-badges__list__item:nth-child(4n) {
    margin-right: 0;
    margin-left: auto;
}

.v-badges__list__item__add {
    color: #3890EE;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.v-badges__list__item__add:hover,
    .v-badges__list__item__add:focus {
        background: rgba(56, 144, 238, 0.2);
    }

.v-badges__list__item__remove {
    position: absolute;
    top: 8px;
    right: 8px;
}

.v-badges__list__item__image {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;

    
    text-align: center;
    min-height: 200px;
}

.v-badges__list__item__image img {
    width: 80%;
}

.v-badges__list__item__image__change {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

/*------------------------------------*\
    # views.levels
\*------------------------------------*/

.v-levels {
}

.v-levels__list {
    display: flex;
    flex-flow: row wrap;
}

.v-levels__list__item {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/4 - (30px - 30px * 1/4));
    max-width: calc(99.9% * 1/4 - (30px - 30px * 1/4));
    width: calc(99.9% * 1/4 - (30px - 30px * 1/4));
    padding: 20px;
    position: relative;
    background: white;
    border-radius: 4px;
    margin-bottom: 28px;
}

.v-levels__list__item:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
}

.v-levels__list__item:last-child {
    margin-right: 0;
}

.v-levels__list__item:nth-child(4n) {
    margin-right: 0;
    margin-left: auto;
}

.v-levels__list__item__remove {
    position: absolute;
    top: -8px;
    right: -8px;
}

/*------------------------------------*\
    # views.errorpage
\*------------------------------------*/

.v-errorpage {
    padding-top: 40px;
}

.v-errorpage__content {
    
}

/**
* Import: utils
* Description: reusable utilities such as floats, spacers etc.
*/

/*------------------------------------*\
    # utils.grid
\*------------------------------------*/

.g-split {
    display: flex;
    flex-flow: row wrap;
}

.g-split__item {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/2 - (30px - 30px * 1/2));
    max-width: calc(99.9% * 1/2 - (30px - 30px * 1/2));
    width: calc(99.9% * 1/2 - (30px - 30px * 1/2));
}

.g-split__item:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
}

.g-split__item:last-child {
    margin-right: 0;
}

.g-split__item:nth-child(2n) {
    margin-right: 0;
    margin-left: auto;
}

.g-three {
    display: flex;
    flex-flow: row wrap;
}

.g-three__item {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/3 - (30px - 30px * 1/3));
    max-width: calc(99.9% * 1/3 - (30px - 30px * 1/3));
    width: calc(99.9% * 1/3 - (30px - 30px * 1/3));
}

.g-three__item:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
}

.g-three__item:last-child {
    margin-right: 0;
}

.g-three__item:nth-child(3n) {
    margin-right: 0;
    margin-left: auto;
}

.g-inline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.g-inline__item {
    padding-right: 24px;
}

.g-spacebetween {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.g-blocks {
    display: flex;
    flex-flow: row wrap;
}

.g-blocks__item {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/2 - (30px - 30px * 1/2));
    max-width: calc(99.9% * 1/2 - (30px - 30px * 1/2));
    width: calc(99.9% * 1/2 - (30px - 30px * 1/2));
    margin-bottom: 30px;
}

.g-blocks__item:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
}

.g-blocks__item:last-child {
    margin-right: 0;
}

.g-blocks__item:nth-child(2n) {
    margin-right: 0;
    margin-left: auto;
}

/*------------------------------------*\
    # utils.width
\*------------------------------------*/

.u-width--full {
    width: 100%;
}

.u-width--half {
    width: 50%;
}

/*------------------------------------*\
    # utils.group
\*------------------------------------*/

.u-group:after {
        content: '';
        display: block;
        clear: both;
    }

/*------------------------------------*\
    # utils.spacers
\*------------------------------------*/

.s- {
}

/* Top */

.s-top {
}

.s-top--tny {
    margin-top: 4px;
}

.s-top--sml {
    margin-top: 8px;
}

.s-top--med {
    margin-top: 16px;
}

.s-top--lrg {
    margin-top: 32px;
}

.s-top--xlrg {
    margin-top: 48px;
}

/* Right */

.s-right {
}

.s-right--tny {
    margin-right: 4px;
}

.s-right--sml {
    margin-right: 8px;
}

.s-right--med {
    margin-right: 16px;
}

.s-right--lrg {
    margin-right: 32px;
}

.s-right--xlrg {
    margin-right: 48px;
}

/* Bottom */

.s-bottom {
}

.s-bottom--tny {
    margin-bottom: 4px;
}

.s-bottom--sml {
    margin-bottom: 8px;
}

.s-bottom--med {
    margin-bottom: 16px;
}

.s-bottom--lrg {
    margin-bottom: 32px;
}

.s-bottom--xlrg {
    margin-bottom: 48px;
}

/* Left */

.s-left {
}

.s-left--tny {
    margin-left: 4px;
}

.s-left--sml {
    margin-left: 8px;
}

.s-left--med {
    margin-left: 16px;
}

.s-left--lrg {
    margin-left: 32px;
}

.s-left--xlrg {
    margin-left: 48px;
}

/*------------------------------------*\
    # utils.type
\*------------------------------------*/

/* Alignment */

.t-left {
    text-align: left;
}

.t-center {
    text-align: center;
}

.t-right {
    text-align: right;
}

/* Case */

.t-upper {
    text-transform: uppercase;
}

/* Sizes */

.t-alpha {
    font-size: 39px;
    line-height: 48px;
}

.t-beta {
    font-size: 31px;
    line-height: 40px;
}

.t-gamma {
    font-size: 25px;
    line-height: 36px;
}

.t-delta {
    font-size: 18px;
    line-height: 28px;
}

.t-epsilon {
    font-size: 16px;
    line-height: 24px;
}

.t-zeta {
    font-size: 14px;
    line-height: 20px;
}

.t-eta {
    font-size: 12px;
    line-height: 12px;
}

/* Colors */

.t-primary {
    color: #3890EE;
}

.t-secondary {
    color: #666666;
}

.t-tertiary {
    color: #5985ff;
}

.t-complementary {
    color: orange;
}

.t-negative {
    color: white;
}

/* Shorten long links */

.t-shorten {
    /* These are technically the same, but use both */
    word-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
}

.t-wrap {
    word-wrap: break-word;
    word-wrap: break-word;
}

/* Mandatory filed asterisk*/

.t-mandatory::after {
        color: black;
        content: ' *';
        display: inline;
    }

/*------------------------------------*\
    # utils.float
\*------------------------------------*/

.u-float {
}

.u-float--left {
    float: left;
}

.u-float--right {
    float: right;
}

/*------------------------------------*\
    # utils.position
\*------------------------------------*/

.u-pos {
}

.u-pos--rel {
  position: relative;
}

.u-pos--abs {
  position: absolute;
}

/* z-index positioning */

.u-z-0 {
  z-index: 0;
}

.u-z-5 {
  z-index: 5;
}

/* z-index positioning */

.u-z-0 {
    z-index: 0;
}

.u-z-5 {
    z-index: 5;
}

/*------------------------------------*\
    # utils.bg
\*------------------------------------*/

.u-bg {
}

.u-bg--primary {
    background-color: #3890EE;
}

/*------------------------------------*\
    # utils.color
\*------------------------------------*/

.u-color {
}

.u-color--success {
    color: green;
}

.u-color--warning {
    color: #ee386f;
}

.u-color--note {
    color: orange;
}

.u-color--text {
    color: #111;
}

/*------------------------------------*\
    # utils.opacity
\*------------------------------------*/

.o-100 {
  opacity: 1;
}

.o-80 {
  opacity: 0.8;
}

.o-60 {
  opacity: 0.6;
}

.o-40 {
  opacity: 0.4;
}

.o-20 {
  opacity: 0.2;
}

.o-zero {
  opacity: 0;
}

/*------------------------------------*\
    # utils.list
\*------------------------------------*/

.l-delimited {
    display: flex;
    flex-flow: row wrap;
}

.l-delimited dt {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 3/12 - (30px - 30px * 3/12));
    max-width: calc(99.9% * 3/12 - (30px - 30px * 3/12));
    width: calc(99.9% * 3/12 - (30px - 30px * 3/12));
    margin-bottom: 8px;
    opacity: 0.6;
}

.l-delimited dt:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
}

.l-delimited dt:last-child {
    margin-right: 0;
}

.l-delimited dt:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
}

.l-delimited dd {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 9/12 - (30px - 30px * 9/12));
    max-width: calc(99.9% * 9/12 - (30px - 30px * 9/12));
    width: calc(99.9% * 9/12 - (30px - 30px * 9/12));
    margin-bottom: 8px;
}

.l-delimited dd:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
}

.l-delimited dd:last-child {
    margin-right: 0;
}

.l-delimited dd:nth-child(2n) {
    margin-right: 0;
    margin-left: auto;
}

.l-boxed {
}

.l-boxed__item {
    padding: 16px;
    background: white;
    border-radius: 4px;
    margin-bottom: 8px;
}

.l-boxed__item:last-child {
        margin-bottom: 0;
    }

.l-bordered {
    border: 1px solid rgba(56, 144, 238, 0.4);
    border-radius: 4px;
    padding: 16px;
}

.l-bordered__item {
    padding: 16px 0;
    border-bottom: 1px solid rgba(34, 34, 34, 0.1);
}

.l-bordered__item:last-child {
        border-bottom: 0;
    }

/*------------------------------------*\
    # utils.display
\*------------------------------------*/

.u-display {
}

.u-display--b {
    display: block;
}

.u-display--ib {
    display: inline-block;
}

/*------------------------------------*\
    # utils.well
\*------------------------------------*/

.u-well {
    padding: 20px;
    border-radius: 4px;
}

.u-well--primary {
    background: rgba(56, 144, 238, 0.1);
    border: 1px solid rgba(56, 144, 238, 0.2);
}

.u-well--dropzone {
    background: rgba(56, 144, 238, 0.1);
    border: 1px dashed rgba(56, 144, 238, 0.2);
}

/**
* Import: plugins
* Description: 3rd party code, external plugin CSS etc
* Note: when importing code from a 3rd party it should be stripped off
* any vendor prefixes since autoprefixer will use project specific vendor prefixes
*/

/*------------------------------------*\
    # plugins.fontface
\*------------------------------------*/

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/fonts/Inter-Regular.woff2') format('woff2'),
        url('../assets/fonts/Inter-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 400;
    src: url('../assets/fonts/Inter-Italic.woff2') format('woff2'),
        url('../assets/fonts/Inter-Italic.woff') format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    src: url('../assets/fonts/Inter-Bold.woff2') format('woff2'),
        url('../assets/fonts/Inter-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 700;
    src: url('../assets/fonts/Inter-BoldItalic.woff2') format('woff2'),
        url('../assets/fonts/Inter-BoldItalic.woff') format('woff');
}

/*------------------------------------*\
    # plugins.toastify
\*------------------------------------*/

.Toastify__toast-container {
    z-index: 9999;
    position: fixed;
    padding: 4px;
    width: 320px;
    color: white;
}

.Toastify__toast-container--top-left {
    top: 1em;
    left: 1em;
}

.Toastify__toast-container--top-center {
    top: 1em;
    left: 50%;
    margin-left: -160px;
}

.Toastify__toast-container--top-right {
    top: 1em;
    right: 1em;
}

.Toastify__toast-container--bottom-left {
    bottom: 1em;
    left: 1em;
}

.Toastify__toast-container--bottom-center {
    bottom: 1em;
    left: 50%;
    margin-left: -160px;
}

.Toastify__toast-container--bottom-right {
    bottom: 1em;
    right: 1em;
}

@media only screen and (max-width: 480px) {
    .Toastify__toast-container {
        width: 100vw;
        padding: 0;
        left: 0;
        margin: 0;
    }

    .Toastify__toast-container--top-left,
    .Toastify__toast-container--top-center,
    .Toastify__toast-container--top-right {
        top: 0;
    }

    .Toastify__toast-container--bottom-left,
    .Toastify__toast-container--bottom-center,
    .Toastify__toast-container--bottom-right {
        bottom: 0;
    }

    .Toastify__toast-container--rtl {
        right: 0;
        left: auto;
        left: initial;
    }
}

.Toastify__toast {
    position: relative;
    min-height: 48px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin-bottom: 1rem;
    padding: 8px 24px;
    border-radius: 1px;
    -webkit-box-shadow: 0 1px 10px 0 rgba(0, 0, 0, .1),
        0 2px 15px 0 rgba(0, 0, 0, .05);
            box-shadow: 0 1px 10px 0 rgba(0, 0, 0, .1),
        0 2px 15px 0 rgba(0, 0, 0, .05);
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
            justify-content: space-between;
    max-height: 800px;
    overflow: hidden;
    cursor: pointer;
    direction: ltr;

    font-size: 14px;

    line-height: 16px;
}

.Toastify__toast--rtl {
    direction: rtl;
}

.Toastify__toast--default {
    background: white;
    color: #aaa;
}

.Toastify__toast--info {
    background: #3890EE;
}

.Toastify__toast--success {
    background: green;
}

.Toastify__toast--warning {
    background: #ee386f;
}

.Toastify__toast--error {
    background: orange;
}

.Toastify__toast-body {
    margin: auto 0;
    -ms-flex: 1;
    -webkit-box-flex: 1;
            flex: 1;
}

@media only screen and (max-width: 480px) {
    .Toastify__toast {
        margin-bottom: 0;
    }
}

.Toastify__close-button {
    color: white;
    font-weight: bold;
    font-size: 14px;
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;
    opacity: 0.7;
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
    -ms-flex-item-align: start;
    align-self: flex-start;
}

.Toastify__close-button--default {
    color: #000;
    opacity: 0.3;
}

.Toastify__close-button:hover,
.Toastify__close-button:focus {
    opacity: 1;
}

@-webkit-keyframes Toastify__trackProgress {
    0% {
        -webkit-transform: scaleX(1);
                transform: scaleX(1);
    }

    100% {
        -webkit-transform: scaleX(0);
                transform: scaleX(0);
    }
}

@keyframes Toastify__trackProgress {
    0% {
        -webkit-transform: scaleX(1);
                transform: scaleX(1);
    }

    100% {
        -webkit-transform: scaleX(0);
                transform: scaleX(0);
    }
}

.Toastify__progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    z-index: 9999;
    opacity: 0.7;
    background-color: rgba(255, 255, 255, .7);
    -webkit-transform-origin: left;
            transform-origin: left;
}

.Toastify__progress-bar--animated {
    -webkit-animation: Toastify__trackProgress linear 1 forwards;
            animation: Toastify__trackProgress linear 1 forwards;
}

.Toastify__progress-bar--controlled {
    -webkit-transition: -webkit-transform 0.2s;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
}

.Toastify__progress-bar--rtl {
    right: 0;
    left: auto;
    left: initial;
    -webkit-transform-origin: right;
            transform-origin: right;
}

.Toastify__progress-bar--default {
    background-color: rgba(0, 0, 0, .7);
}

@-webkit-keyframes Toastify__bounceInRight {
    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    from {
        opacity: 0;
        -webkit-transform: translate3d(3000px, 0, 0);
                transform: translate3d(3000px, 0, 0);
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(-25px, 0, 0);
                transform: translate3d(-25px, 0, 0);
    }
    75% {
        -webkit-transform: translate3d(10px, 0, 0);
                transform: translate3d(10px, 0, 0);
    }
    90% {
        -webkit-transform: translate3d(-5px, 0, 0);
                transform: translate3d(-5px, 0, 0);
    }
    to {
        -webkit-transform: none;
                transform: none;
    }
}

@keyframes Toastify__bounceInRight {
    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    from {
        opacity: 0;
        -webkit-transform: translate3d(3000px, 0, 0);
                transform: translate3d(3000px, 0, 0);
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(-25px, 0, 0);
                transform: translate3d(-25px, 0, 0);
    }
    75% {
        -webkit-transform: translate3d(10px, 0, 0);
                transform: translate3d(10px, 0, 0);
    }
    90% {
        -webkit-transform: translate3d(-5px, 0, 0);
                transform: translate3d(-5px, 0, 0);
    }
    to {
        -webkit-transform: none;
                transform: none;
    }
}

@-webkit-keyframes Toastify__bounceOutRight {
    20% {
        opacity: 1;
        -webkit-transform: translate3d(-20px, 0, 0);
                transform: translate3d(-20px, 0, 0);
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
                transform: translate3d(2000px, 0, 0);
    }
}

@keyframes Toastify__bounceOutRight {
    20% {
        opacity: 1;
        -webkit-transform: translate3d(-20px, 0, 0);
                transform: translate3d(-20px, 0, 0);
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
                transform: translate3d(2000px, 0, 0);
    }
}

@-webkit-keyframes Toastify__bounceInLeft {
    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-3000px, 0, 0);
                transform: translate3d(-3000px, 0, 0);
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(25px, 0, 0);
                transform: translate3d(25px, 0, 0);
    }
    75% {
        -webkit-transform: translate3d(-10px, 0, 0);
                transform: translate3d(-10px, 0, 0);
    }
    90% {
        -webkit-transform: translate3d(5px, 0, 0);
                transform: translate3d(5px, 0, 0);
    }
    to {
        -webkit-transform: none;
                transform: none;
    }
}

@keyframes Toastify__bounceInLeft {
    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-3000px, 0, 0);
                transform: translate3d(-3000px, 0, 0);
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(25px, 0, 0);
                transform: translate3d(25px, 0, 0);
    }
    75% {
        -webkit-transform: translate3d(-10px, 0, 0);
                transform: translate3d(-10px, 0, 0);
    }
    90% {
        -webkit-transform: translate3d(5px, 0, 0);
                transform: translate3d(5px, 0, 0);
    }
    to {
        -webkit-transform: none;
                transform: none;
    }
}

@-webkit-keyframes Toastify__bounceOutLeft {
    20% {
        opacity: 1;
        -webkit-transform: translate3d(20px, 0, 0);
                transform: translate3d(20px, 0, 0);
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
                transform: translate3d(-2000px, 0, 0);
    }
}

@keyframes Toastify__bounceOutLeft {
    20% {
        opacity: 1;
        -webkit-transform: translate3d(20px, 0, 0);
                transform: translate3d(20px, 0, 0);
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(-2000px, 0, 0);
                transform: translate3d(-2000px, 0, 0);
    }
}

@-webkit-keyframes Toastify__bounceInUp {
    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 3000px, 0);
                transform: translate3d(0, 3000px, 0);
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, -20px, 0);
                transform: translate3d(0, -20px, 0);
    }
    75% {
        -webkit-transform: translate3d(0, 10px, 0);
                transform: translate3d(0, 10px, 0);
    }
    90% {
        -webkit-transform: translate3d(0, -5px, 0);
                transform: translate3d(0, -5px, 0);
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}

@keyframes Toastify__bounceInUp {
    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 3000px, 0);
                transform: translate3d(0, 3000px, 0);
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, -20px, 0);
                transform: translate3d(0, -20px, 0);
    }
    75% {
        -webkit-transform: translate3d(0, 10px, 0);
                transform: translate3d(0, 10px, 0);
    }
    90% {
        -webkit-transform: translate3d(0, -5px, 0);
                transform: translate3d(0, -5px, 0);
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}

@-webkit-keyframes Toastify__bounceOutUp {
    20% {
        -webkit-transform: translate3d(0, -10px, 0);
                transform: translate3d(0, -10px, 0);
    }
    40%,
    45% {
        opacity: 1;
        -webkit-transform: translate3d(0, 20px, 0);
                transform: translate3d(0, 20px, 0);
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
                transform: translate3d(0, -2000px, 0);
    }
}

@keyframes Toastify__bounceOutUp {
    20% {
        -webkit-transform: translate3d(0, -10px, 0);
                transform: translate3d(0, -10px, 0);
    }
    40%,
    45% {
        opacity: 1;
        -webkit-transform: translate3d(0, 20px, 0);
                transform: translate3d(0, 20px, 0);
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -2000px, 0);
                transform: translate3d(0, -2000px, 0);
    }
}

@-webkit-keyframes Toastify__bounceInDown {
    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -3000px, 0);
                transform: translate3d(0, -3000px, 0);
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, 25px, 0);
                transform: translate3d(0, 25px, 0);
    }
    75% {
        -webkit-transform: translate3d(0, -10px, 0);
                transform: translate3d(0, -10px, 0);
    }
    90% {
        -webkit-transform: translate3d(0, 5px, 0);
                transform: translate3d(0, 5px, 0);
    }
    to {
        -webkit-transform: none;
                transform: none;
    }
}

@keyframes Toastify__bounceInDown {
    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -3000px, 0);
                transform: translate3d(0, -3000px, 0);
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, 25px, 0);
                transform: translate3d(0, 25px, 0);
    }
    75% {
        -webkit-transform: translate3d(0, -10px, 0);
                transform: translate3d(0, -10px, 0);
    }
    90% {
        -webkit-transform: translate3d(0, 5px, 0);
                transform: translate3d(0, 5px, 0);
    }
    to {
        -webkit-transform: none;
                transform: none;
    }
}

@-webkit-keyframes Toastify__bounceOutDown {
    20% {
        -webkit-transform: translate3d(0, 10px, 0);
                transform: translate3d(0, 10px, 0);
    }
    40%,
    45% {
        opacity: 1;
        -webkit-transform: translate3d(0, -20px, 0);
                transform: translate3d(0, -20px, 0);
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
                transform: translate3d(0, 2000px, 0);
    }
}

@keyframes Toastify__bounceOutDown {
    20% {
        -webkit-transform: translate3d(0, 10px, 0);
                transform: translate3d(0, 10px, 0);
    }
    40%,
    45% {
        opacity: 1;
        -webkit-transform: translate3d(0, -20px, 0);
                transform: translate3d(0, -20px, 0);
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 2000px, 0);
                transform: translate3d(0, 2000px, 0);
    }
}

.Toastify__bounce-enter--top-left,
.Toastify__bounce-enter--bottom-left {
    -webkit-animation-name: Toastify__bounceInLeft;
            animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--top-right,
.Toastify__bounce-enter--bottom-right {
    -webkit-animation-name: Toastify__bounceInRight;
            animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
    -webkit-animation-name: Toastify__bounceInDown;
            animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
    -webkit-animation-name: Toastify__bounceInUp;
            animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left,
.Toastify__bounce-exit--bottom-left {
    -webkit-animation-name: Toastify__bounceOutLeft;
            animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--top-right,
.Toastify__bounce-exit--bottom-right {
    -webkit-animation-name: Toastify__bounceOutRight;
            animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
    -webkit-animation-name: Toastify__bounceOutUp;
            animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
    -webkit-animation-name: Toastify__bounceOutDown;
            animation-name: Toastify__bounceOutDown;
}

@-webkit-keyframes Toastify__zoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
                transform: scale3d(0.3, 0.3, 0.3);
    }
    50% {
        opacity: 1;
    }
}

@keyframes Toastify__zoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
                transform: scale3d(0.3, 0.3, 0.3);
    }
    50% {
        opacity: 1;
    }
}

@-webkit-keyframes Toastify__zoomOut {
    from {
        opacity: 1;
    }
    50% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
                transform: scale3d(0.3, 0.3, 0.3);
    }
    to {
        opacity: 0;
    }
}

@keyframes Toastify__zoomOut {
    from {
        opacity: 1;
    }
    50% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
                transform: scale3d(0.3, 0.3, 0.3);
    }
    to {
        opacity: 0;
    }
}

.Toastify__zoom-enter {
    -webkit-animation-name: Toastify__zoomIn;
            animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
    -webkit-animation-name: Toastify__zoomOut;
            animation-name: Toastify__zoomOut;
}

@-webkit-keyframes Toastify__flipIn {
    from {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
                transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        opacity: 0;
    }
    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
                transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
    }
    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
                transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
    }
    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
                transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }
    to {
        -webkit-transform: perspective(400px);
                transform: perspective(400px);
    }
}

@keyframes Toastify__flipIn {
    from {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
                transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        opacity: 0;
    }
    40% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
                transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
    }
    60% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
                transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
    }
    80% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
                transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }
    to {
        -webkit-transform: perspective(400px);
                transform: perspective(400px);
    }
}

@-webkit-keyframes Toastify__flipOut {
    from {
        -webkit-transform: perspective(400px);
                transform: perspective(400px);
    }
    30% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
                transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        opacity: 1;
    }
    to {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
                transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0;
    }
}

@keyframes Toastify__flipOut {
    from {
        -webkit-transform: perspective(400px);
                transform: perspective(400px);
    }
    30% {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
                transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        opacity: 1;
    }
    to {
        -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
                transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        opacity: 0;
    }
}

.Toastify__flip-enter {
    -webkit-animation-name: Toastify__flipIn;
            animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
    -webkit-animation-name: Toastify__flipOut;
            animation-name: Toastify__flipOut;
}

@-webkit-keyframes Toastify__slideInRight {
    from {
        -webkit-transform: translate3d(110%, 0, 0);
                transform: translate3d(110%, 0, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}

@keyframes Toastify__slideInRight {
    from {
        -webkit-transform: translate3d(110%, 0, 0);
                transform: translate3d(110%, 0, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}

@-webkit-keyframes Toastify__slideInLeft {
    from {
        -webkit-transform: translate3d(-110%, 0, 0);
                transform: translate3d(-110%, 0, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}

@keyframes Toastify__slideInLeft {
    from {
        -webkit-transform: translate3d(-110%, 0, 0);
                transform: translate3d(-110%, 0, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}

@-webkit-keyframes Toastify__slideInUp {
    from {
        -webkit-transform: translate3d(0, 110%, 0);
                transform: translate3d(0, 110%, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}

@keyframes Toastify__slideInUp {
    from {
        -webkit-transform: translate3d(0, 110%, 0);
                transform: translate3d(0, 110%, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}

@-webkit-keyframes Toastify__slideInDown {
    from {
        -webkit-transform: translate3d(0, -110%, 0);
                transform: translate3d(0, -110%, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}

@keyframes Toastify__slideInDown {
    from {
        -webkit-transform: translate3d(0, -110%, 0);
                transform: translate3d(0, -110%, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}

@-webkit-keyframes Toastify__slideOutRight {
    from {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    to {
        visibility: hidden;
        -webkit-transform: translate3d(110%, 0, 0);
                transform: translate3d(110%, 0, 0);
    }
}

@keyframes Toastify__slideOutRight {
    from {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    to {
        visibility: hidden;
        -webkit-transform: translate3d(110%, 0, 0);
                transform: translate3d(110%, 0, 0);
    }
}

@-webkit-keyframes Toastify__slideOutLeft {
    from {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    to {
        visibility: hidden;
        -webkit-transform: translate3d(-110%, 0, 0);
                transform: translate3d(-110%, 0, 0);
    }
}

@keyframes Toastify__slideOutLeft {
    from {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    to {
        visibility: hidden;
        -webkit-transform: translate3d(-110%, 0, 0);
                transform: translate3d(-110%, 0, 0);
    }
}

@-webkit-keyframes Toastify__slideOutDown {
    from {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, 500px, 0);
                transform: translate3d(0, 500px, 0);
    }
}

@keyframes Toastify__slideOutDown {
    from {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, 500px, 0);
                transform: translate3d(0, 500px, 0);
    }
}

@-webkit-keyframes Toastify__slideOutUp {
    from {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, -500px, 0);
                transform: translate3d(0, -500px, 0);
    }
}

@keyframes Toastify__slideOutUp {
    from {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, -500px, 0);
                transform: translate3d(0, -500px, 0);
    }
}

.Toastify__slide-enter--top-left,
.Toastify__slide-enter--bottom-left {
    -webkit-animation-name: Toastify__slideInLeft;
            animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--top-right,
.Toastify__slide-enter--bottom-right {
    -webkit-animation-name: Toastify__slideInRight;
            animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
    -webkit-animation-name: Toastify__slideInDown;
            animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
    -webkit-animation-name: Toastify__slideInUp;
            animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left,
.Toastify__slide-exit--bottom-left {
    -webkit-animation-name: Toastify__slideOutLeft;
            animation-name: Toastify__slideOutLeft;
}

.Toastify__slide-exit--top-right,
.Toastify__slide-exit--bottom-right {
    -webkit-animation-name: Toastify__slideOutRight;
            animation-name: Toastify__slideOutRight;
}

.Toastify__slide-exit--top-center {
    -webkit-animation-name: Toastify__slideOutUp;
            animation-name: Toastify__slideOutUp;
}

.Toastify__slide-exit--bottom-center {
    -webkit-animation-name: Toastify__slideOutDown;
            animation-name: Toastify__slideOutDown;
}

/*------------------------------------*\
    # plugins.slate
\*------------------------------------*/

.slate {
    border-radius: 4px;
    border: 1px solid rgba(34, 34, 34, 0.15);
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

.slate__toolbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-bottom: 1px solid rgba(34, 34, 34, 0.15);
}

.slate__toolbar__icon {
    display: block;
    padding: 8px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    opacity: 0.5;
}

.slate__toolbar__icon:hover,
    .slate__toolbar__icon:focus {
        opacity: 1;
    }

.slate__editor {
    font-size: 14px;
    line-height: 20px;
    padding: 16px;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

.slate__editor:hover,
    .slate__editor:focus {
        background: rgba(34, 34, 34, 0.05);
    }

.slate__editor__image {
    max-height: 400px;
    width: auto;
}

.slate__editor__video {
    width: 300px;
}

.slate__gallery {
    cursor: pointer;
    padding: 8px;
    border: 1px solid rgba(34, 34, 34, 0.2);
    border-radius: 4px;
}

.slate__gallery:hover {
        border-color: #3890EE;
    }

.slate__gallery img {
        max-height: 80px;
        width: auto;
        margin-right: 4px;
        padding: 8px 0;
    }

.slate__modal-section {
    visibility: hidden;
    opacity: 0;
    max-height: 0;

    -webkit-transition: visibility 0.33s linear, opacity 0.33s linear,
        background 1s linear;

    transition: visibility 0.33s linear, opacity 0.33s linear,
        background 1s linear;
}

.slate__modal-section.visible {
        visibility: visible;
        opacity: 1;

        -webkit-transition-delay: 0s;

                transition-delay: 0s;
        max-height: 60vh;
    }

.slate__modal__gallery {
    width: 100%;
    border: 2px solid rgba(56, 144, 238, 0.8);
    border-radius: 4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    padding: 10px 0 0 10px;
    overflow-y: auto;
    margin-bottom: 16px;
}

.slate__modal__gallery-item {
        height: 140px;
        width: 140px;
        border: 2px solid rgba(56, 144, 238, 0.2);
        margin-right: 10px;
        margin-bottom: 10px;
        -webkit-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;
        padding: 4px;
    }

.slate__modal__gallery-item:hover {
            cursor: pointer;
            border: 2px solid #3890EE;
        }

.slate__modal__gallery-item.selected {
            border: 2px solid #3890EE;
            background-color: rgba(56, 144, 238, 0.2);
        }

.slate__modal__gallery-item .thumbnail-img {
            height: 64px;
            width: 64px;
            -o-object-fit: cover;
               object-fit: cover;
            padding: 1px;
        }

.slate__modal__gallery-item .slate__modal__image {
            height: 100%;
            width: 100%;
            -o-object-fit: cover;
               object-fit: cover;
            padding: 1px;
        }

.slate__modal__video-item {
    border: 2px solid rgba(56, 144, 238, 0.2);
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 10px;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    padding: 4px;
    height: 100%;
    width: 32%;
}

.slate__modal__video-item:hover {
        cursor: pointer;
        border: 2px solid #3890EE;
    }

.slate__modal__video-item.selected {
        border: 2px solid #3890EE;
        background-color: rgba(56, 144, 238, 0.2);
    }

.slate__modal__video-item p {
        color: rgba(56, 144, 238, 0.8);
        padding: 8px 0;
        max-width: 304px;
    }

.slate__modal-radio {
    padding-bottom: 16px;
}

.slate__modal-radio label {
        line-height: 30px;
    }

.slate__modal__uploaded-images {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    overflow-x: scroll;
}

.slate__modal__uploaded-images li {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto;
        margin-right: 8px;
    }

.slate__modal__uploaded-images img {
        height: 120px;
        width: auto;
        padding: 8px 0;
        -o-object-fit: cover;
           object-fit: cover;
    }

.DateRangePicker .DateInput_input {
    font-size: 14px;
}

.DateRangePicker .DateInput_input__focused {
    border-bottom-color: #3890EE;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background: #3890EE !important;
    border-color: #3890EE !important;
}

.CalendarDay__selected_span {
    background: rgba(56, 144, 238, 0.5) !important;
    border-color: rgba(56, 144, 238, 0.5) !important;
}

/**
* Import: shame
* Description: CSS shame file
* Note: to be avoided, exists only if REALLY necessary or legacy code
*/

/* @import 'shame.css'; */
